import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const Passerelle = (props) => {
  return (
    <Card className="text-center">
      <Card.Header>{props.passerelle.name}</Card.Header>
      <Card.Body>
        
        {(props.passerelle.cashPoint && props.type == "depot") && <Card.Text>
          <h3>Composez ce code pour vos dépôt</h3>
          {props.passerelle.operateur.map((o, index) => <h4><span className='text-success'>#111*1*4*1*{o.phone}*{props.amount}#</span><br/>({o.name})</h4> )}
        </Card.Text>}
        {(!props.passerelle.cashPoint && !props.passerelle.tpe && props.type == "depot") && <Card.Text>
          <h3>Transférez exactement la somme de <span className='text-danger'>{props.amount} Ariary</span> (sans frais de retrait) au: </h3>
          <h4>{props.passerelle.operateur.map((o, index) => <span className='text-success'>{`${o.phone} (${o.name})`}<br/></span> )}</h4>
        </Card.Text>}
        {(props.passerelle.auto && props.passerelle.tpe && props.type == "depot") && <Card.Text>
            <h4>Veuillez maintenir votre téléphone allumé, et accepter la demande de débit envoyée par <br/>{props.passerelle.operateur.map((o, index) => <span className='text-success'>{`${o.name} (${o.phone})`}<br/></span> )}</h4>
        </Card.Text>}
      </Card.Body>
      {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
    </Card>
  );
}

export default Passerelle;